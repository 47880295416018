const {PublishMethods, factory} = require('@wix/web-bi-logger/dist/src/logger')
const {create, phasesConfig} = require('@wix/fedops-logger')

const DEFAULT_ENDPOINT = 'bolt-viewer'

const serverLoggerFactory = (biStore, shouldBeMutedForFedOps = () => false) => factory({
    publishMethod: PublishMethods.PostMessage
}).setMuted(shouldBeMutedForFedOps())
    .withUoUContext({
        msid: biStore.msid,
        visitorId: biStore.visitorId,
        siteMemberId: () => biStore.siteMemberId
    })
    .withTransformer({
        [PublishMethods.PostMessage]: params => ({
            type: 'reportBI',
            params
        })
    })

const clientLoggerFactory = (biStore, shouldBeMutedForFedOps = () => false) => factory({
    publishMethod: PublishMethods.Auto
})
    .setMuted(shouldBeMutedForFedOps())
    .withUoUContext({
        msid: biStore.msid,
        visitorId: biStore.visitorId,
        siteMemberId: () => biStore.siteMemberId
    })

const getFedOpsServerLogger = (biStore, muteFunc) => create(DEFAULT_ENDPOINT, {
    isServerSide: true,
    biLoggerFactory: serverLoggerFactory(biStore, muteFunc),
    phasesConfig: phasesConfig.SEND_ON_FINISH,
    metasiteId: biStore.msid
})

const getFedOpsClientLogger = (biStore, muteFunc) => create(DEFAULT_ENDPOINT, {
    isServerSide: false,
    biLoggerFactory: clientLoggerFactory(biStore, muteFunc),
    phasesConfig: phasesConfig.SEND_ON_FINISH,
    metasiteId: biStore.msid
})

module.exports = {
    getFedOpsClientLogger,
    getFedOpsServerLogger
}

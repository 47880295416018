function getWorkerUrlOverride() {
    if (typeof window === 'undefined') { //debug feature that works only in client
        return false
    }
    try {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get('workerUrlOverride')
    } catch (e) {
        return false
    }
}

export function createWorkerUrlOverride() {
    const workerUrlOverride = getWorkerUrlOverride()
    console.log('fetching worker url from: ', workerUrlOverride)
    return fetch(workerUrlOverride)
        .then(res => new Response(res.body))
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
}

export function isWorkerUrlOverrideExists() {
    return !!getWorkerUrlOverride()
}
